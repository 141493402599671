export const LOGIN = 'auth/login'
export const FETCH_VENDOR_REQUESTS = 'vendor/fetch-vendor-requests'
export const GET_COINS = 'user/fetch-user-coin-details'
export const ADD_DEDUCT_COIN = 'user/add-deduct-coin'
export const USER_SEARCHES = 'user/user-search-term'
export const DELETE_SEARCH_TEARM = 'user/delete-search-term'
export const GET_User = 'user/fetch-customer-by-id'
export const GET_REWARD = '/Reward/fetch-reward'
export const REWARD_TRANSACTION = '/Reward/fetch-reward-transactions'
export const BULK_REWARD = '/Reward/Bulk-approve-reject'
export const SELLER_BULK_REWARD = '/Reward/seller-bulk-approve-reject'
export const WITHDRAWAL_BULK_APP_REJ = '/kikoorders/Bulk-approve-reject'
export const REWARD_SELLER_TRANSACTION = '/Reward/fetch-seller-reward-transactions'
export const REWARD_SELLER = '/Reward/fetch-seller-reward'
export const GET_SEMI_VENDOR = '/user/fetch-user'
export const GET_SELLER = '/user/fetch-seller'
export const ONDC_SELLER = '/user/fetch-ondc-seller'
export const VIEW_DELIVERY = '/kikoorders/view-delivery'
export const SEARCH_SELLER = '/user/search-seller'
export const GET_BUYERS_ADDRESS = '/user/fetch-buyers-address'
export const ADD_EDIT_ADDRESS = '/user/add-edit-address'
export const GET_ADDRESS_DETAIL = '/user/fetch-address-detail'
export const GET_CUSTOMER_LISTED_SELLER = '/user/get-customer-listed-sellers'
export const GET_USER_DETAILS = '/user/fetch-user-detail'
export const BLOCK_DEVICEID = '/user/block-userid'
export const TASKBUCKS_BUYERS = '/marketing-leads/taskbucks-buyers'
export const PICKMYWORKS_USERS = '/marketing-leads/get-pickmywork-users'
export const EDIT_SELLER = '/user/edit-Seller-details'
export const UPDATE_VENDOR_REQUEST = 'vendor/update-vendor-request'
export const FETCH_VENDOR_REQUEST_BY_ID = 'vendor/fetch-vendor-request-by-id'
export const FETCH_ORDER = '/kikoorders/fetch-orders'
export const FETCH_COUPON_CATELOGUE = 'sellerOndc/fetch-offer-catalogue'
export const SEARCH_AUTOCOMPLETE_CATELOGUE = 'sellerOndc/autoComplete-catelogue-search'
export const CREATE_ODNC_COUPON = 'sellerOndc/create-ondc-offers'
export const FETCH_SELLER_ORDER_INVOICE = '/user/fetch-ondc-sellers-invoice'
export const FETCH_DELIVERY_COSTING_DATA = '/user/get-delivery-costing'
export const UPDATE_DELIVERY_COSTING_DATA = '/user/update-ondc-delivery-priority'
export const FETCH_KIKOPAYMENTS = '/kikoorders/NAC-kikoPayments'
export const VIEW_KIKOPAYMENTS = '/kikoorders/view-kikoPayments'
export const UPDATE_WITHDRAWAL_STATUS = '/kikoorders/update-withdrawal-status'
export const FETCH_CUSTOMER_ORDER = '/kikoorders/fetch-customer-orders'
export const BUYER_DELIVERED_ORDER = '/kikoorders/buyer-delivered-orders'
export const FETCH_COURIER_INFO = '/kikoorders/fetch-courier-info'
export const FETCH_REWARD = '/kikoorders/fetch-reward-orders'
export const FETCH_COUPAN = '/kikoorders/reward-coupon'
export const FETCH_DELIVERY_ORDERS = '/kikoorders/fetch-delivery-orders'
export const FETCH_DELIVERY_DATA = '/kikoorders/fetch-delivery-data'
export const FETCH_LONG_ORDER = '/kikoorders/fetch-long-distance-orders'
export const VIEW_ORDER = '/kikoorders/view-orders'
export const VIEW_BUDDY_REWARD = '/Reward/view-buddy-reward'
export const VIEW_WITHDRAWAL = '/kikoorders/fetch-invoice-withdrawal'
export const VIEW_SELLER_REWARD = '/Reward/view-seller-reward'
export const SEARCH_ORDER = '/kikoorders/search-orders'
export const SEARCH_USERS = 'user/search-users'
export const BLOCK_USER = 'user/block-user'
export const DELETE_USER = 'user/delete-user'
export const GET_CATEGORY = 'categories/get-categories'
export const GET_CATEGORY_CATEGORIES = "/sellerOndc/get-catalogue-categories";
export const ADD_CATEGORY = 'categories/add-categories'
export const DELETE_CATEGORY = 'categories/delete-categories'
export const DELETE_ORDER = '/kikoorders/delete-orders'
export const VIDEO_CALL_ORDERS = '/kikoOrders/fetch-video-call-orders'
export const ADD_ENQUIRY = 'callenquiries/add-enquiry'
export const Delete_ENQUIRY = 'callenquiries/delete-Enquiry'
export const GET_ENQUIRIES = 'callenquiries/get-enquiries'
export const FETCH_VIDEOS = '/videos/fetch-videos'
export const FETCH_EVENTS = '/events/fetch-events'
export const FETCH_FLAGS = '/flag/flag'
export const ADD_VIDEOS = '/videos/add-videos'
export const BULK_VIDEOS = '/videos/Bulk-approve-reject'
export const DELETE_VIDEOS = '/videos/delete-videos'
export const DELETE_FLAG = '/flag/delete-flag'
export const UPDATE_FLAG = '/flag/Update-Flag'
export const UPDATE_EVENTS = '/events/create-event'
export const FETCH_SEMI_VENODR = '/user/fetch-semiVendor'
export const REVIEW_EVENTS = '/events/fetch-event-Id'
export const VIEW_USERS = '/events/view-user'
export const FETCH_DESHBOARD = "/user/deshboard-count-users"
export const FETCH_VENDOR_PEMISSION_BY_USERID = "/user/fetch-vendor-Permission-by-UserId"
export const CHANGE_SEMIVENDOR_BY_ID = "/user/change-role"
export const GET_INFLUENCER = "/user/fetch-influencer"
export const FETCH_REMITTANCE_STATUS = "/kikoOrders/Bulk-approve-reject"
export const UPDATE_SEMIVENDOR_PERMISSION = "/user/update-users-permission"
export const FETCH_SELLER_WALLET = "/seller-wallet/fetch-seller-wallet-details"
export const FETCH_ACCOUNT_WITHDRAWAL = "/kikoorders/fetch-account-withdrawal"
export const GET_OFFER_WALLET = "/seller-wallet/get_offer-wallet-details"
export const FETCH_OFFER_WALLET = "/seller-wallet/featch-offer_wallet-details"
export const DELETE_OFFER = "/seller-wallet/delete-offer"
export const CURRENT_OFFER = "/seller-wallet/current-offer"
export const EVENTS_FEEDBACKS = '/events/event-feedbacks'
export const FEATCH_USER_BY_EVENTS = "/events/view-user-by-event"
export const FETCH_INVOICE_ORDER = '/kikoorders/fetch-invoice-orders'
export const FETCH_REWARDS = "/products/fetch-products"
export const ADD_PRODUCTS = "/products/add-products"
export const DELETE_PRODUCTS = "/products/del-products"
export const FETCH_PRODUCT_BY_ID = "products/fetch-products-by-Id"
export const FETCH_DELETED_ACCOUNTS = "/user/fetch-deleted-accounts"
export const CHANGE_INFLUENCER_ROLE = "/user/change-influencer-role"
export const SEARCH_AUTOCOMPLETE_USER = "/user/search-autocomplete_user"
export const SEARCH_AUTOCOMPLETE_STORE = "/user/search-autocomplete_store"
export const FETCH_SETTINGS = '/user/fetch-settings'
export const UPDATE_APP_SETTINGS = '/user/update-app-settings'
export const FETCH_SELLER_CATALOGUES = "/sellerOndc/get-catalogues-for-admin"
export const UPDATE_SELLER_CATALOGUES = "/sellerOndc/update-catalogues-status"
export const DELETE_INVENTORY = "/sellerOndc/delete-catalogue";
export const UPDATE_CATELOGUE = "/sellerOndc/update-catalogues";
export const UPDATE_SETTLEMENT = "/sellerOndc/update-settlement-status";
export const UPDATE_SETTLEMENT_EXCEL = "/sellerOndc/update-settlement-status-excel";
export const MICROWEBSITE_ORDERS = '/kikoorders/microWebsite-orders-list';
export const MICROWEBSITE_SETTLEMENT = '/kikoorders/update-microwebsite-settlement';
export const ONDC_ORDER = "sellerOndc/get-ondc-orders";
export const ONDC_RETURN_ORDER = "sellerOndc/get-ondc-return";
export const ONDC_SETTLEMENT_ORDER = "sellerOndc/get-ondc-settlement-orders"
export const RSP_SETTLEMENT = "sellerOndc/rsp-settlement-details";
export const FETCH_MASTER_CATALOGUES = "/sellerOndc/get-master-catalogues-for-admin"
export const UPDATE_MASTER_CATALOGUES_STATUS = "/sellerOndc/update-master-catalogues-status"
export const DELETE_MASTER_INVENTORY = "/sellerOndc/delete-master-catalogue";
export const UPDATE_MASTER_CATELOGUE = "/sellerOndc/update-master-catalogues";
export const FETCH_ONDC_ORDER_ADMIN = "/sellerOndc/get-ondc-orders-Admin";
export const ADMIN_ONDC_OFFERS = "sellerOndc/get-admin-ondc-offer";
export const UPDATE_ONDC_OFFERS_STATUS = "sellerOndc/update-offer-status";
export const DELETE_ONDC_OFFER = "/sellerOndc/delete-ondc-offer";
export const REPORT_ONDC_ORDER_ADMIN = "/reports/get-ondc-orders-Admin";
export const REPORT_CATALOGUES_ADMIN = "/reports/get-catalogues-for-admin";
export const REPORT_ITEM_WISE_ORDER = "/reports/get-ondc-orders";
export const REPORT_ONDC_SETTLEMENT = "/reports/get-ondc-settlement-orders";
export const REPORT_ONDC_RETURN_ORDER = "/reports/get-ondc-return";
export const REPORT_SELLER_MANAGER = "/reports/fetch-seller";
export const REPORT_ONDC_SELLER_MANAGER = "/reports/fetch-ondc-seller";
export const REPORT_SELLER_INVOICE = "/reports/fetch-ondc-sellers-invoice";
export const REPORT_GST_MANAGER = "/reports/get-gst-settlement-orders";
export const SEND_SETTLEMENT_EMAIL = "/sellerOndc/send-ondc-settlement-mail";
export const UPDATE_CATALOGUE_EAN = "/sellerOndc/catalogue-update-ean";
export const Bulk_UPDATE_CATALOGUE_EAN = "/sellerOndc/admin-update-catelogue-price";
export const FETCH_ORDER_LOG = "/sellerOndc/fetch-order-log";
export const FETCH_SELLER_LOG = "/sellerOndc/fetch-seller-log";

// buyer side api's

export const BUYER_LOGIN = "buyerondc/buyer-login";
export const FETCH_BUYER_COUNT = "buyerondc/dashboard-count-buyers";
export const FETCH_BUYER_ONDC_ORDER_ADMIN = "buyerondc/get-buyer-ondc-orders-Admin"
